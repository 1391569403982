<template>
  <div class="pa-4">
    <patient-register-form v-if="progress==''"
      id="patient-register-form"
      :item="currentItem"
      :beforeAnalysisOpinionType="beforeAnalysisOpinionType"
      :isContractedAnalysisOption="isContractedAnalysisOption"
      :mode="mode"
      :serial="serial"
      :password="password"
      @confirm="enterConfirm"
    />
    <v-dialog
      v-model="isDisplay"
      @input="reenter"
      max-width="1200"
    >
      <patient-register-confirm v-if="progress=='confirm'"
        :item="currentItem"
        :beforeAnalysisOpinionType="beforeAnalysisOpinionType"
        :isContractedAnalysisOption="isContractedAnalysisOption"
        :mode="mode"
        :status=3
        @reenter="reenter"
        @complete="enterComplete"
        @close="closeDialog"
      />
      <patient-register-complete v-else-if="progress=='complete'"
        :item="currentItem"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>

import PatientRegisterConfirm from '@/components/PatientRegisterConfirm.vue';
import PatientRegisterComplete from '@/components/PatientRegisterComplete.vue';
import PatientRegisterForm from '@/components/PatientRegisterForm.vue';


export default {
  name: 'PatientRegister',
  components: {
    PatientRegisterForm,
    PatientRegisterConfirm,
    PatientRegisterComplete
  },
  props: {
  },
  data() {
    return {
      currentItem: null,
      progress: '',
      mode: 'new',
      beforeAnalysisOpinionType: null,
      isContractedAnalysisOption: null,
      isUpdatedCustomerOrgsDict: false,
      serial: '',
      password: '',
    }
  },
  methods: {
    reenter() {
      this.progress = '';
    },
    enterConfirm(inputedItem) {
      this.currentItem = inputedItem;
      this.progress = 'confirm';
    },
    enterComplete(confirmedItem) {
      this.currentItem = confirmedItem;
      this.progress = 'complete';
    },
    closeDialog() {
      console.log('PatientDialog closeDialog');
      this.currentItem = null;
      this.reenter();
    },
    getAnalysisOptionInfo(orgData) {
      // 変更前の解析オプション
      this.beforeAnalysisOpinionType = ('analysis_opinion_type' in orgData && orgData.analysis_opinion_type !== null) ? parseInt(orgData.analysis_opinion_type) : 0;
      // オプション解析契約状況
      this.isContractedAnalysisOption = ('is_contracted_analysis_option' in orgData && orgData.is_contracted_analysis_option == true) ? true : false;
    },
  },
  mounted() {
    console.log('PatientRegisterForm mounted');
    this.serial = this.$route.query.serial || '';
    this.password = this.$route.query.password || '';

    if (this.$store.state.user.org_id in this.customerOrgsDict) {
      // 組織情報取得済み
      this.isUpdatedCustomerOrgsDict = true;
      this.getAnalysisOptionInfo(this.customerOrgsDict[this.$store.state.user.org_id]);
    }
  },
  watch: {
    customerOrgsDict(value) {
      if (!this.isUpdatedCustomerOrgsDict) {
        // 最初の一回だけ
        this.isUpdatedCustomerOrgsDict = true;
        this.getAnalysisOptionInfo(value[this.$store.state.user.org_id]);
      }
    },
  },
  computed: {
    isDisplay: {
      get() {
        return this.progress ? true : false;
      },
      set(progress) {
        this.progress = progress;
      }
    },
    customerOrgsDict() {
      return this.$store.state.customerOrgsDict;
    },
  }
}
</script>

<style>
#patient-register-form .v-card {
  box-shadow: none !important;
}
#patient-register-form .v-card__title {
    font-size: 1.5rem !important;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal !important;
    font-family: "Roboto", sans-serif !important;
    background-color: white !important;
    border-color: white !important;
    color: rgba(0, 0, 0, 0.87) !important;
    padding: 12px !important;
}
#patient-register-form .v-card__text {
  padding: 0 !important;
}
</style>